/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import qs from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Modal, ModalBody, Button, ModalHeader, FormGroup, Input, Label, Row, Col } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { debounce } from 'lodash'

import calendarIcon from '../../../assets/images/calendar.svg'

import UserHeader from '../Component/UsersListHeader'
import DepositManagementContent from './DepositManagement'
import UsersListMainHeader from '../Component/UsersListMainHeader'

import { getFirstDeportReport } from '../../../actions/matchleague'
import { getDepositList, getDepositsTotalCount, getDepositPaymentGateways } from '../../../actions/deposit'
import { getUrl } from '../../../actions/url'
import { getRecommendedList } from '../../../actions/users'
import { useMutation, useQuery } from '@tanstack/react-query'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'
import SortableColumn from '../PassbookManagement/sortableColumn'

function DepositManagement (props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const content = useRef()

  const [search, setSearch] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [paymentStatus, setPaymentStatus] = useQueryState('status', '')
  const [depositPaymentMethod, setDepositPaymentMethod] = useQueryState('method', '')
  const [disableButton, setDisableButton] = useState(false)
  const [modalReport, setModalReport] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [dateRangeReport, setDateRangeReport] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [startDateReport, endDateReport] = dateRangeReport
  const [depositPaymentList, setDepositPaymentList] = useState([])
  const [shouldDownloadReport, setShouldDownloadReport] = useState(false) // Track whether report should be downloaded
  const [userId, setUserId] = useQueryState('iUserId', '')
  const [newUserList, setNewUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState([])
  const [userInput, setUserInput] = useState('')
  const [userName, setUserName] = useState('')
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()
  const [order] = useQueryState('order', 'desc')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [sort] = useQueryState('sortBy', 'dCreatedAt')

  const token = useSelector(state => state?.auth?.token)
  const depositList = useSelector(state => state?.deposit?.depositList)
  const depositPaymentType = useSelector(state => state?.deposit?.depositPaymentList)
  const depositReportDetails = useSelector(state => state?.matchleague?.firsDepositReport)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const recommendedList = useSelector(state => state?.users?.recommendedList)

  const modalToggle = () => {
    setModalReport(!modalReport)
    setDateRangeReport([null, null])
  }

  useEffect(() => {
    const obj = qs?.parse(location.search)
    if (obj?.searchValue) {
      setSearch(obj?.searchValue)
    }
    if (obj?.userId) {
      setUserName(obj?.userId)
    }
    if (obj?.status) {
      setPaymentStatus(obj?.status)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
    dispatch(getUrl('media'))
    onGetRecommendedList()
  }, [])

  useEffect(() => {
    if (depositPaymentType) {
      setDepositPaymentList(depositPaymentType)
    }
  }, [depositPaymentType])

  useEffect(() => {
    dispatch(getDepositPaymentGateways(token))
  }, [depositPaymentMethod])

  function onHandleSearch (e) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    }
    setSearch(e?.target?.value)
    setinitialFlag(true)
  }

  useEffect(() => {
    if (recommendedList) {
      const newUserList = recommendedList.map((user) => ({
        label: user.sUsername,
        value: user._id
      }))
      setNewUserList(newUserList)
    }
  }, [recommendedList])

  // this forwardRef user Date Filter
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt="calendar" className='calenderIcon' src={calendarIcon}/>
      <Input ref={ref} className='date-input range ' placeholder='Select Date Range' readOnly style={{ width: '100%' }} value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput

  // dispatch action to get depositsTotalCount
  function getDepositsTotalCountFunc (searchText, status, method, dateFrom, dateTo, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const depositListData = {
      search: searchText, status, method, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', isFullResponse, token, iUserId: userId
    }
    dispatch(getDepositsTotalCount(depositListData))
  }

  function getList (start, limit, sort, order, searchText, status, method, dateFrom, dateTo, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const depositListData = {
      start, limit, sort, order, search: searchText?.trim(), status, method, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', isFullResponse, token, iUserId: userId
    }
    // dispatch action to get deposit List
    dispatch(getDepositList(depositListData))
  }

  function onExport () {
    content?.current?.onExport()
  }

  function onRefresh () {
    content?.current?.onRefresh()
  }

  function onStatusChange (event) {
    setPaymentStatus(event?.target?.value)
  }

  function onMethodChange (event) {
    setDepositPaymentMethod(event?.target?.value)
  }

  function depositReport (startDate, endDate, token) {
    const StartDate = startDate ? new Date(moment(startDate)?.startOf('day')?.format()) : ''
    const EndDate = endDate ? new Date(moment(endDate)?.endOf('day')?.format()) : ''
    const ReportData = {
      startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', token
    }
    setDisableButton(true)
    dispatch(getFirstDeportReport(ReportData))
    setModalReport(false)
    setShouldDownloadReport(true) // Set flag to true upon submission
  }

  useEffect(() => {
    if (shouldDownloadReport && depositReportDetails && getUrlLink) {
      setDisableButton(false)
      downloadFile(`${getUrlLink}${depositReportDetails?.key}`, 'Deposit Report')
      setShouldDownloadReport(false) // Reset flag after download
    }
  }, [shouldDownloadReport, depositReportDetails?.key, getUrlLink])

  function getDefaultColumn () {
    const data = [
      { sColumnName: 'Transaction ID', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'User Type', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Mobile No.', bShow: true },
      { sColumnName: 'Amount', bShow: true },
      { sColumnName: 'Promo Code', bShow: true },
      { sColumnName: 'Deposit Date', bShow: true },
      { sColumnName: 'Gateway Info', bShow: true },
      { sColumnName: 'Logs', bShow: true },
      { sColumnName: 'Actions', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return ({
          ...c,
          bShow: !value
        })
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  function handleSubmit () {
    mutation.mutate({ sModuleName: 'DEPOSITS', aColumnOrder: columnNames })
  }

  const { data: columnData } = useQuery({
    queryKey: ['DEPOSITS'],
    queryFn: () => getDynamicColumns('DEPOSITS'),
    select: (data) => data?.data?.data
  })

  useEffect(() => {
    if (columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else getDefaultColumn()
  }, [columnData])

  const downloadFile = (reportUrl, reportName) => {
    try {
      fetch(reportUrl)
        .then(response => response?.blob())
        .then(blob => {
          const url = window?.URL?.createObjectURL(blob)
          const depositReport = document?.createElement('a')
          depositReport.href = url
          depositReport.download = reportName || 'downloaded_file'
          document?.body?.appendChild(depositReport)
          depositReport?.click()
          document?.body?.removeChild(depositReport)
          window?.URL?.revokeObjectURL(url)
        })
        .catch(error => console?.error('Error downloading file:', error))
    } catch (error) {
      console?.error('An error occurred:', error)
    }
  }

  function handleAdminSearch (e) {
    setUserName(e?.target?.value)
    onGetRecommendedList()
    setinitialFlag(true)
  }

  function onGetRecommendedList (data, senID) {
    dispatch(getRecommendedList(data, senID, token))
  }

  const debouncedGetRecommendedList = useCallback(
    debounce((ID) => {
      dispatch(getRecommendedList(ID, false, token))
    }, 300),
    [dispatch, token]
  )

  const handleInputChange = useCallback((inputValue) => {
    setUserInput(inputValue)
    if (inputValue.length >= 3) {
      debouncedGetRecommendedList(inputValue)
    } else {
      setNewUserList([])
    }
  }, [debouncedGetRecommendedList])

  function onFiltering (selectedOption) {
    if (selectedOption) {
      setUserId(selectedOption?.value)
      setSelectedUser(selectedOption)
      // Selected user mate data fetch karo
      getList(start, offset, sort, order, search, paymentStatus, depositPaymentMethod, startDate, endDate, false, selectedOption.value)
      getDepositsTotalCountFunc(search, paymentStatus, depositPaymentMethod, startDate, endDate, false, selectedOption.value)
    } else {
      // Filter remove thay tyare badhu reset karo
      setUserId('')
      setSelectedUser(null)
      setUserInput('')
      setNewUserList([])
      // Original list fetch karo, user filter vagar
      getList(start, offset, sort, order, search, paymentStatus, depositPaymentMethod, startDate, endDate, false, '')
      getDepositsTotalCountFunc(search, paymentStatus, depositPaymentMethod, startDate, endDate, false, '')
    }
  }

  return (
    <Fragment>
      <main className="main-content">
        <section className="management-section common-box">
          <UsersListMainHeader
            heading="Deposits"
            list={depositList}
            onExport={onExport}
            onRefresh={onRefresh}
            refresh = 'Refersh Deposits Data'
          />
          <div className={ depositList?.length !== 0 && depositList?.rows?.length !== 0 ? 'setting-component' : 'deposit-component'}>
            <UserHeader
              dateRange={dateRange}
              depositPaymentMethod={depositPaymentMethod}
              disableButton={disableButton}
              endDate={endDate}
              handleSearch={onHandleSearch}
              heading="Deposits"
              list={depositList}
              modalToggle={modalToggle}
              onMethodChange={onMethodChange}
              onStatusChange={onStatusChange}
              paymentStatus={paymentStatus}
              search={search}
              setDateRange={setDateRange}
              startDate={startDate}
              deposits
              depositPaymentType={depositPaymentType}
              depositPaymentList={depositPaymentList}
              handleInputChange={handleInputChange}
              selectedUser={selectedUser}
              onFiltering={onFiltering}
              newUserList={newUserList}
              // adminSearch={userName}
              handleAdminSearch={handleAdminSearch}
              setIsManageColumn={setIsManageColumn}
            />
            <DepositManagementContent
              {...props}
              ref={content}
              List={depositList}
              endDate={endDate}
              flag={initialFlag}
              getDepositsTotalCountFunc={getDepositsTotalCountFunc}
              getList={getList}
              search={search}
              startDate={startDate}
              viewLink="/users/user-management/user-details"
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              recommendedList={recommendedList}
              userId={userId}
              adminSearch={userName}
              onGetRecommendedList={onGetRecommendedList}
              columnNames={columnNames}
              start={start}
              setStart={setStart}
              order={order}
              offset={offset}
              setOffset={setOffset}
              sort={sort}
            />
          </div>
        </section>
      </main>

      <Modal className='firstDepositReport' isOpen={modalReport} toggle={modalToggle}>
        <ModalHeader className='report-header' toggle={modalToggle}> Admin First Deposit Report </ModalHeader>
        <ModalBody className='p-4'>
          <Label className='report-date-label' for='sports-date'> Select Date Range </Label>
          <FormGroup className='form-group'>
            <DatePicker
              className='w-100'
              customInput={<ExampleCustomInput />}
              dropdownMode="select"
              endDate={endDateReport}
              isClearable={true}
              maxDate={new Date()}
              onChange={(update) => { setDateRangeReport(update) }}
              peekNextMonth
              placeholderText='Select Date Range'
              selectsRange={true}
              showMonthDropdown
              showYearDropdown
              startDate={startDateReport}
              value={dateRangeReport}
            />
          </FormGroup>
          <Button className='w-100 mt-3 report-button' onClick={() => depositReport(startDateReport, endDateReport, token)}>Submit</Button>
        </ModalBody>
      </Modal>

      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col >

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

DepositManagement.propTypes = {
  location: PropTypes.object,
  onClick: PropTypes.func,
  value: PropTypes.string
}

export default DepositManagement
