//Auth
export const CLEAR_AUTH_RESPONSE = 'CLEAR_AUTH_RESPONSE'
export const LOGIN = 'LOGIN'
export const TOKEN_LOGIN = 'TOKEN_LOGIN'
export const LOGIN_OTP = 'LOGIN_OTP'
export const SEND_OTP = 'SEND_OTP'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGE_NAME = 'CHANGE_NAME'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const GET_PROFILE = 'GET_PROFILE'
export const IS_LOADING = 'IS_LOADING'

//error
export const ERROR_MSG = 'ERROR_MSG'
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG'

//logout
export const LOGOUT = 'LOGOUT'
export const CLEAR_AUTH_PROPS = 'CLEAR_AUTH_PROPS'

//Admin Roles
export const ROLE_LIST = 'ROLE_LIST'
export const ACTIVE_ROLE_LIST = 'ACTIVE_ROLE_LIST'
export const ROLE_DETAILS = 'ROLE_DETAILS'
export const CLEAR_ROLE_MESSAGE = 'CLEAR_ROLE_MESSAGE'
export const ADD_ROLE = 'ADD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

//Permissions
export const ADD_PERMISSION_MATCH = 'ADD_PERMISSION_MATCH'
export const CLEAR_PERMISSION_MESSAGE = 'CLEAR_PERMISSION_MESSAGE'
export const PERMISSION_DETAILS = 'PERMISSION_DETAILS'
export const PERMISSION_LIST = 'PERMISSION_LIST'
export const PERMISSION_STATUS_LIST = 'PERMISSION_STATUS_LIST'
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION'

//Admin Logs 
export const GET_KYC_URL = 'GET_KYC_URL'
export const GET_URL = 'GET_URL'
export const KYC_URL = 'KYC_URL'

//Sub Admin
export const ADD_SUB_ADMIN = 'ADD_SUB_ADMIN'
export const ADMIN_IDS = 'ADMIN_IDS'
export const ADMIN_LOGS = 'ADMIN_LOGS'
export const CLEAR_SUB_ADMIN_MESSAGE = 'CLEAR_SUB_ADMIN_MESSAGE'
export const LEAGUE_LOGS = 'LEAGUE_LOGS'
export const MATCH_API_DETAILS = 'MATCH_API_DETAILS'
export const MATCH_API_LOGS = 'MATCH_API_LOGS'
export const MATCH_LOGS = 'MATCH_LOGS'
export const SINGLE_ADMIN_LOGS = 'SINGLE_ADMIN_LOGS'
export const SUB_ADMIN_DETAILS = 'SUB_ADMIN_DETAILS'
export const SUB_ADMIN_LIST = 'SUB_ADMIN_LIST'
export const UPDATE_SUB_ADMIN = 'UPDATE_SUB_ADMIN'

//Users
export const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS'
export const GET_RECOMMANDED_LIST = 'GET_RECOMMANDED_LIST'
export const ADD_USER_DEPOSIT = 'ADD_USER_DEPOSIT'
export const ADD_USER_WITHDRAW = 'ADD_USER_WITHDRAW'
export const CITIES_LIST = 'CITIES_LIST'
export const CLEAR_USERS_MESSAGE = 'CLEAR_USERS_MESSAGE'
export const EMAIL_TEMPLATE_DETAILS = 'EMAIL_TEMPLATE_DETAILS'
export const EMAIL_TEMPLATE_LIST = 'EMAIL_TEMPLATE_LIST'
export const GET_BALANCE_DETAILS = 'GET_BALANCE_DETAILS'
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS'
export const GET_PREFERENCE_DETAILS = 'GET_PREFERENCE_DETAILS'
export const IMAGE_EMAIL_TEMPLATE = 'IMAGE_EMAIL_TEMPLATE'
export const STATES_LIST = 'STATES_LIST'
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const UPDATE_PREFERENCE_DETAILS = 'UPDATE_PREFERENCE_DETAILS'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const USER_DETAIL_LIST = 'USER_DETAIL_LIST'
export const USER_LIST = 'USER_LIST'
export const USERS_TOTAL_COUNT = 'USERS_TOTAL_COUNT'
export const TDS_LIST = 'TDS_LIST'
export const TDS_TOTAL_COUNT = 'TDS_TOTAL_COUNT'
export const UPDATE_TDS = 'UPDATE_TDS'
export const GET_REFERRALS_LIST = 'GET_REFERRALS_LIST'
export const DELETED_USER_LIST = 'DELETED_USER_LIST'
export const GET_DROPPED_USER_LIST = 'GET_DROPPED_USER_LIST'
export const ADD_AFFILIATE_WITHDRAW = 'ADD_AFFILIATE_WITHDRAW'

//Match
export const ADD_MATCH = 'ADD_MATCH'
export const CLEAR_MATCH_MESSAGE = 'CLEAR_MATCH_MESSAGE'
export const FETCH_MATCH = 'FETCH_MATCH'
export const GENERATE_PDF = 'GENERATE_PDF'
export const LOAD_LIVE_LEADER_BOARD = 'LOAD_LIVE_LEADER_BOARD'
export const MATCH_DETAILS = 'MATCH_DETAILS'
export const MATCH_LIST = 'MATCH_LIST'
export const MATCH_DATA_REFRESH = 'MATCH_DATA_REFRESH'
export const MERGE_MATCH = 'MERGE_MATCH'
export const POST_PREVIEW = 'POST_PREVIEW'
export const PRIZE_DISTRIBUTION = 'PRIZE_DISTRIBUTION'
export const UPCOMING_MATCH_LIST = 'UPCOMING_MATCH_LIST'
export const UPDATE_MATCH = 'UPDATE_MATCH'
export const WIN_PRIZE_MATCH_DISTRIBUTION = 'WIN_PRIZE_MATCH_DISTRIBUTION'
export const SCORE_CARD = 'SCORE_CARD'
export const LIVE_INNINGS = 'LIVE_INNINGS'
export const EXTRA_WIN_LIST = 'EXTRA_WIN_LIST'
export const GENERATE_DREAM_TEAM = 'GENERATE_DREAM_TEAM'
export const GET_BASE_TEAMS_LISTING = 'GET_BASE_TEAMS_LISTING'
export const MATCH_LIST_INREVIEW = 'MATCH_LIST_INREVIEW'
export const MATCH_LIST_LIVE = 'MATCH_LIST_LIVE'
export const UPCOMING_MATCHES_TOTAL_COUNT = 'UPCOMING_MATCHES_TOTAL_COUNT'
export const LIVE_MATCHES_TOTAL_COUNT = 'LIVE_MATCHES_TOTAL_COUNT'
export const INREVIEW_MATCHES_TOTAL_COUNT = 'INREVIEW_MATCHES_TOTAL_COUNT'
export const MATCHES_TOTAL_COUNT = 'MATCHES_TOTAL_COUNT'
export const GET_EXPERT_TEAMS_LISTING = 'GET_EXPERT_TEAMS_LISTING'

//Banner
export const BANNER_DETAILS = 'BANNER_DETAILS'
export const ADD_BANNER = 'ADD_BANNER'
export const BANNER_LIST = 'BANNER_LIST'
export const BANNER_STATISTICS = 'BANNER_STATISTICS'
export const CLEAR_BANNER_MESSAGE = 'CLEAR_BANNER_MESSAGE'
export const DELETE_BANNER = 'DELETE_BANNER'
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const CLEAR_BANNER_PROPS = 'CLEAR_BANNER_PROPS'

//Match League
export const ADD_MATCH_LEAGUE = 'ADD_MATCH_LEAGUE'
export const ADD_SYSTEM_TEAMS = 'ADD_SYSTEM_TEAMS'
export const BANNER_MATCH_LEAGUE_LIST = 'BANNER_MATCH_LEAGUE_LIST'
export const AGENT_COUNT_MATCH_LEAGUE = 'AGENT_COUNT_MATCH_LEAGUE'
export const AGENT_DETAILS_MATCH_CONTEST = 'AGENT_DETAILS_MATCH_CONTEST'
export const CANCEL_MATCH_LEAGUE = 'CANCEL_MATCH_LEAGUE'
export const CLEAR_MATCH_LEAGUE_MESSAGE = 'CLEAR_MATCH_LEAGUE_MESSAGE'
export const MATCH_LEAGUE_DETAILS = 'MATCH_LEAGUE_DETAILS'
export const LEAGUE_COUNT = 'LEAGUE_COUNT'
export const MATCH_LEAGUE_LIST = 'MATCH_LEAGUE_LIST'
export const MATCH_LEAGUE_REPORT = 'MATCH_LEAGUE_REPORT'
export const NORMAL_AGENT_TEAMS = 'NORMAL_AGENT_TEAMS'
export const POINT_CALCULATE = 'POINT_CALCULATE'
export const PROMO_USAGE_LIST = 'PROMO_USAGE_LIST'
export const UPDATE_AGENT_STATUS = 'UPDATE_AGENT_STATUS'
export const USERS_CASHBACK_LIST = 'USERS_CASHBACK_LIST'
export const USER_LEAGUE_LIST = 'USER_LEAGUE_LIST'
export const USER_TEAM_LIST = 'USER_TEAM_LIST'
export const USER_TEAM_PLAYER_LIST = 'USER_TEAM_PLAYER_LIST'
export const GET_USER_COPY_TEAMS = 'GET_USER_COPY_TEAMS'
export const UPDATE_CLONE_AGENT = 'UPDATE_CLONE_AGENT'
export const GET_EXCEL_REPORT = 'GET_EXCEL_REPORT'
export const RESET_MATCH_LEAGUE = 'RESET_MATCH_LEAGUE'
export const CLONE_AGENT_TEAM_PLAYER_LIST = 'CLONE_AGENT_TEAM_PLAYER_LIST'
export const FIRST_DEPOSIT_REPORT = 'FIRST_DEPOSIT_REPORT'

//Sports
export const CLEAR_SPORT_MESSAGE = 'CLEAR_SPORT_MESSAGE'
export const SPORTS_LIST = 'SPORTS_LIST'
export const SPORT_DETAILS = 'SPORT_DETAILS'
export const UPDATE_SPORT = 'UPDATE_SPORT'

//Match League
export const NORMAL_BOT_TEAMS = 'NORMAL_BOT_TEAMS'
export const UPDATE_BOT_STATUS = 'UPDATE_BOT_STATUS'
export const BOT_COUNT_MATCH_LEAGUE = 'UPDATE_BOT_STATUS'
export const BOT_DETAILS_MATCH_CONTEST = 'BOT_DETAILS_MATCH_CONTEST'

//Versions and maintanance 
export const VERSION_LIST = 'VERSION_LIST'
export const CLEAR_VERSION_MESSAGE = 'CLEAR_VERSION_MESSAGE'
export const VERSION_DETAILS = 'VERSION_DETAILS'
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const ADD_VERSION = 'ADD_VERSION'
export const UPDATE_MAINTENANCE_MODE = 'UPDATE_MAINTENANCE_MODE'
export const GET_MAINTENANCE_MODE = 'GET_MAINTENANCE_MODE'
export const DELETE_VERSION = 'DELETE_VERSION'

//Notification
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const ADD_TIME_NOTIFICATION = 'ADD_TIME_NOTIFICATION'
export const ADD_USER_NOTIFICATION = 'ADD_USER_NOTIFICATION'
export const CLEAR_NOTIFICATIONS_MESSAGE = 'CLEAR_NOTIFICATIONS_MESSAGE'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const GET_NOTIFICATION_DETAILS = 'GET_NOTIFICATION_DETAILS'
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST'
export const TYPE_LIST = 'TYPE_LIST'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'

//KYC
export const ADD_AADHAAR_DETAILS = 'ADD_AADHAAR_DETAILS'
export const ADD_PAN_DETAILS = 'ADD_PAN_DETAILS'
export const CLEAR_KYC_MESSAGE = 'CLEAR_KYC_MESSAGE'
export const GET_KYC_INFO = 'GET_KYC_INFO'
export const KYC_DETAILS = 'KYC_DETAILS'
export const KYC_LIST = 'KYC_LIST'
export const PENDING_KYC_COUNT = 'PENDING_KYC_COUNT'
export const UPDATE_AADHAAR_DETAILS = 'UPDATE_AADHAAR_DETAILS'
export const UPDATE_KYC_STATUS = 'UPDATE_KYC_STATUS'
export const UPDATE_PAN_DETAILS = 'UPDATE_PAN_DETAILS'

//Categories 
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const CLEAR_CATEGORY_MESSAGE = 'CLEAR_CATEGORY_MESSAGE'
export const CATEGORY_DETAILS = 'CATEGORY_DETAILS'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

//Subcategories
export const SUBCATEGORY_LIST = 'SUBCATEGORY_LIST'
export const CLEAR_SUBCATEGORY_MESSAGE = 'CLEAR_SUBCATEGORY_MESSAGE'
export const SUBCATEGORY_DETAILS = 'SUBCATEGORY_DETAILS'
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY'
export const ADD_SUBCATEGORY = 'ADD_SUBCATEGORY'
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY'

//Settings
export const CHANGE_BG_IMAGE = 'CHANGE_BG_IMAGE'
export const CLEAR_SETTING_MESSAGE = 'CLEAR_SETTING_MESSAGE'
export const CURRENCY_DATA = 'CURRENCY_DATA'
export const GET_VALIDATION = 'GET_VALIDATION'
export const SETTING_DETAILS = 'SETTING_DETAILS'
export const SETTING_LIST = 'SETTING_LIST'
export const SIDE_BG_IMAGE = 'SIDE_BG_IMAGE'
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'
export const UPDATE_SETTING = 'UPDATE_SETTING'

//Events
export const EVENT_LIST = 'EVENT_LIST'
export const CLEAR_EVENTS_MESSAGE = 'CLEAR_EVENTS_MESSAGE'
export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS'
export const EVENT_ADMIN_BUY_COMMISSION = 'EVENT_ADMIN_BUY_COMMISSION'
export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const EVENT_DETAILS = 'EVENT_DETAILS'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DECLARE_OUTCOME = 'DECLARE_OUTCOME'
export const UPDATE_EVENT_STATUS = 'UPDATE_EVENT_STATUS'

//User XP
export const ADD_USER_XP = 'ADD_USER_XP'
export const CLEAR_USER_XP = 'CLEAR_USER_XP'
export const CLEAR_USER_XP_MESSAGE = 'CLEAR_USER_XP_MESSAGE'
export const UPDATE_USER_XP = 'UPDATE_USER_XP'

//Orders
export const ORDERS_TOTAL_COUNT = 'ORDERS_TOTAL_COUNT'
export const CLEAR_ORDERS_MESSAGE = 'CLEAR_ORDERS_MESSAGE'
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS'
export const ORDER_DETAILS_LIST = 'ORDER_DETAILS_LIST'
export const ORDER_LIST = 'ORDER_LIST'
export const ADD_ORDER = 'ADD_ORDER'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const SELL_ORDER = 'SELL_ORDER'
export const ORDER_DETAILS = 'ORDER_DETAILS'

//Push notification
export const PUSH_NOTIFICATION_LIST = 'PUSH_NOTIFICATION_LIST'
export const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION'
export const GET_PUSH_NOTIFICATION_DETAILS = 'GET_PUSH_NOTIFICATION_DETAILS'
export const UPDATE_PUSH_NOTIFICATION = 'UPDATE_PUSH_NOTIFICATION'
export const AUTOMATED_PUSH_NOTIFICATION_LIST = 'AUTOMATED_PUSH_NOTIFICATION_LIST'
export const CLEAR_PUSHNOTIFICATION_MESSAGE = 'CLEAR_PUSHNOTIFICATION_MESSAGE'

//API Logs
export const CLEAR_APILOGS_MESSAGE = 'CLEAR_APILOGS_MESSAGE'
export const GET_TRANSACTION_LOGS = 'GET_TRANSACTION_LOGS'
export const GET_TRANSACTION_BANK_DETAILS = 'GET_TRANSACTION_BANK_DETAILS'

//Deposit
export const CLEAR_DEPOSIT_MESSAGE = 'CLEAR_DEPOSIT_MESSAGE'
export const DEPOSIT_LIST = 'DEPOSIT_LIST'
export const DEPOSIT_TOTAL_COUNT = 'DEPOSIT_TOTAL_COUNT'
export const GET_DEPOSIT_PAYMENT_LIST = 'GET_DEPOSIT_PAYMENT_LIST'
export const UPDATE_DEPOSIT_PAYMENT_STATUS = 'UPDATE_DEPOSIT_PAYMENT_STATUS'

//Passbook
export const CLEAR_PASSBOOK_MESSAGE = 'CLEAR_PASSBOOK_MESSAGE'
export const GENERATE_TRANSACTION_REPORT = 'GENERATE_TRANSACTION_REPORT'
export const GET_TRANSACTION_REPORT_LIST = 'GET_TRANSACTION_REPORT_LIST'
export const GET_TRANSACTION_TYPE_FILTER = 'GET_TRANSACTION_TYPE_FILTER'
export const PASSBOOK_DETAILS = 'PASSBOOK_DETAILS'
export const PASSBOOK_LIST = 'PASSBOOK_LIST'
export const STATISTIC_DETAILS = 'STATISTIC_DETAILS'
export const SYSTEM_USER_PASSBOOK_DETAILS = 'SYSTEM_USER_PASSBOOK_DETAILS'
export const SYSTEM_USER_STATISTIC_DETAILS = 'SYSTEM_USER_STATISTIC_DETAILS'
export const TRANSACTIONS_TOTAL_COUNT = 'TRANSACTIONS_TOTAL_COUNT'

//League
export const DELETE_PRIZE_BREAKUP = 'DELETE_PRIZE_BREAKUP'
export const CLEAR_DELETE_PRIZE_BREAKUP = 'CLEAR_DELETE_PRIZE_BREAKUP'
export const ADD_LEAGUE = 'ADD_LEAGUE'
export const ADD_LEAGUE_PRIZE = 'ADD_LEAGUE_PRIZE'
export const ALL_LEAGUES = 'ALL_LEAGUES'
export const CLEAR_LEAGUE_MESSAGE = 'CLEAR_LEAGUE_MESSAGE'
export const DELETE_LEAGUE = 'DELETE_LEAGUE'
export const GAME_CATEGORY_LIST = 'GAME_CATEGORY_LIST'
export const LEAGUE_DETAILS = 'LEAGUE_DETAILS'
export const LEAGUE_LIST = 'LEAGUE_LIST'
export const LEAGUE_NAME_LIST = 'LEAGUE_NAME_LIST'
export const LEAGUE_PRIZE_DETAILS = 'LEAGUE_PRIZE_DETAILS'
export const LEAGUE_PRIZE_LIST = 'LEAGUE_PRIZE_LIST'
export const UPDATE_LEAGUE = 'UPDATE_LEAGUE'
export const UPDATE_LEAGUE_PRIZE = 'UPDATE_LEAGUE_PRIZE'
export const COPY_LEAGUE = 'COPY_LEAGUE'
export const LEAGUE_ANALYTICS = 'LEAGUE_ANALYTICS'
export const CLEAR_PRIZE_BREAKUP = 'CLEAR_PRIZE_BREAKUP'
export const CONTEST_CATEGORY_ANALYTICS = 'CONTEST_CATEGORY_ANALYTICS'

//Withdraw
export const CLEAR_WITHDRAW_MESSAGE = 'CLEAR_WITHDRAW_MESSAGE'
export const GET_WITHDRWAL_PAYMENT_LIST = 'GET_WITHDRWAL_PAYMENT_LIST'
export const UPDATE_WITHDRAW_PAYMENT_STATUS = 'UPDATE_WITHDRAW_PAYMENT_STATUS'
export const WITHDRAWAL_TOTAL_COUNT = 'WITHDRAWAL_TOTAL_COUNT'
export const WITHDRAW_LIST = 'WITHDRAW_LIST'

//Reports
export const CLEAR_REPORT_MESSAGE = 'CLEAR_REPORT_MESSAGE'
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS'
export const GET_DATE_RANGE_WISE_REPORTS = 'GET_DATE_RANGE_WISE_REPORTS'
export const UPDATE_APP_DOWNLOAD_REPORT = 'UPDATE_APP_DOWNLOAD_REPORT'
export const UPDATE_CASHBACK_REPORT = 'UPDATE_CASHBACK_REPORT'
export const UPDATE_CASHBACK_RETURN_REPORT = 'UPDATE_CASHBACK_RETURN_REPORT'
export const UPDATE_CREATOR_BONUS_REPORT = 'UPDATE_CREATOR_BONUS_REPORT'
export const UPDATE_CREATOR_BONUS_RETURN_REPORT = 'UPDATE_CREATOR_BONUS_RETURN_REPORT'
export const UPDATE_GENERALIZE_REPORT = 'UPDATE_GENERALIZE_REPORT'
export const UPDATE_PARTICIPANTS = 'UPDATE_PARTICIPANTS'
export const UPDATE_PLAYED_REPORT = 'UPDATE_PLAYED_REPORT'
export const UPDATE_PLAY_RETURN_REPORT = 'UPDATE_PLAY_RETURN_REPORT'
export const UPDATE_PRIVATELEAGUE = 'UPDATE_PRIVATELEAGUE'
export const UPDATE_TEAMS = 'UPDATE_TEAMS'
export const UPDATE_WINS = 'UPDATE_WINS'
export const UPDATE_WIN_RETURN = 'UPDATE_WIN_RETURN'
export const UPDATE_APPLICABLE_TAX_REPORT = 'UPDATE_APPLICABLE_TAX_REPORT'
//Socket
export const FETCH_SOCKET = 'FETCH_SOCKET'

//Dashboard 
export const DASHBOARD_DETAILS = 'DASHBOARD_DETAILS'
export const CLEAR_CHARTS_MESSAGE = 'CLEAR_CHARTS_MESSAGE'

//Streaks
export const CLEAR_STREAK_MESSAGE = 'CLEAR_STREAK_MESSAGE'
export const UPDATE_STREAK = 'UPDATE_STREAK'

//Chat
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const CHANGE_CHAT_STATUS = 'CHANGE_CHAT_STATUS'
export const CLEAR_CHAT_MESSAGE = 'CLEAR_CHAT_MESSAGE'