import {  LOGIN, TOKEN_LOGIN, LOGOUT,  ERROR_MSG, CLEAR_ERROR_MSG, GET_PROFILE, CHANGE_PASSWORD, CHANGE_NAME, IS_LOADING } from '../actions/constants'

const AuthReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        adminData: action.payload.data,
        adminPermission: action.payload.permission,
        token: action.payload.token,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case GET_PROFILE:
      return {
        ...state,
        adminData: action.payload.data
      }
    case CHANGE_NAME:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        resStatus: action.payload.resStatus,
        isLoading: action.payload.isLoading,
        resMessage: action.payload.resMessage
      }
    case TOKEN_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        adminData: action.payload.adminData,
        adminPermission: action.payload.permission
      }
    case ERROR_MSG:
      return {
        error: action.payload.error
      }
    case CLEAR_ERROR_MSG:
      return {
        error: ''
      }
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    // case CLEAR_MESSAGE:
    //   return {
    //     ...state,
    //     resStatus: false,
    //     resMessage: '',
    //     error: ''
    //   }
    case LOGOUT:
      return {
        token: undefined
      }
    
    default:
      return state
  }
}
export default AuthReducer