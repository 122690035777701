import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import PropTypes from 'prop-types'
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import AlertMessage from "../../../components/AlertMessage"
import Loading from "../../../components/Loading"
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import RequiredField from "../../../components/RequiredField"
import { isNumber, modalMessageFunc, verifyLength, withoutSpace } from "../../../helpers/helper"
import addCMS from "../../../api/contentManagement/addCMS"
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Alignment, Base64UploadAdapter, BlockQuote, Bold, ClassicEditor, Essentials, Font, Heading, HtmlEmbed, Image, ImageBlock, ImageUpload, Italic, Link, List, MediaEmbed, Mention, Paragraph, Strikethrough, Table, Underline, Undo } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import getCMSDetails from '../../../api/contentManagement/getCMSDetails'
// import getCMSList from '../../../api/contentManagement/getCMSList'
import updateCMS from '../../../api/contentManagement/updateCMS'


const AddContentForm = forwardRef((props, ref) => {
    const { isCreate, setIsEdit, adminPermission, Slug, setSlug, Category, setCategory, Title, setTitle, priority, setPriority, Details, setDetails, setSubmitDisableButton, setIsCreate } = props
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [Description, setDescription] = useState('')
    const [contentStatus, setContentStatus] = useState('N')
    const [errTitle, seterrTitle] = useState('')
    const [errSlug, seterrSlug] = useState('')
    const [errDetails, seterrDetails] = useState('')
    const [errPriority, setErrPriority] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [cmsId, setcmsId] = useState('')
    const [close, setClose] = useState(false)

    const { mutate: addCMSFunction } = useMutation(addCMS, {
        onSuccess: (response) => {
            navigate('/content-management/content', { state: { message: response?.data?.message } })
        },
        onError: (error) => {
            setMessage(error?.response?.data?.message)
            setModalMessage(true)
        }
    })

    //needs to change in future

    const { slug } = useParams()
    const token = useSelector(state => state.auth.token)
    const { data: cmsDetails, isLoading } = useQuery({
        queryKey: ['getCMSDetails', slug],
        queryFn: () => getCMSDetails(slug),
        select: (response) => response?.data?.data,
        enabled: !!slug
    })
    // const { data: cmsList } = useQuery({
    //     queryFn: () => getCMSList(''),
    //     select: (response) => response?.data?.data
    // })
    const resStatus = useSelector(state => state?.cms?.resStatus)
    const resMessage = useSelector(state => state?.cms?.resMessage)
    const previousProps = useRef({ resStatus, resMessage, cmsDetails })?.current
    const [modalMessage, setModalMessage] = useState(false)

    // through this condition if there is no changes in at update time submit button will remain disable
    const submitDisable = cmsDetails && previousProps?.cmsDetails !== cmsDetails && cmsDetails?.sTitle === Title && cmsDetails?.sContent === Details && cmsDetails?.sSlug === Slug &&
        cmsDetails?.sDescription === Description && cmsDetails?.eStatus === contentStatus && cmsDetails?.nPriority === parseInt(priority) && cmsDetails?.sCategory === Category

    const { mutate: updateCMSFunction } = useMutation(updateCMS, {
        onSuccess: (response) => {
            queryClient?.invalidateQueries('getCMS')
            setMessage(response?.data?.message)
            setModalMessage(true)
            setStatus(true)
        }
    })
    // for set content Details
    useEffect(() => {
        if (cmsDetails && Object.keys(cmsDetails)?.length !== 0) {
            setcmsId(cmsDetails?._id)
            setSlug(cmsDetails?.sSlug)
            setTitle(cmsDetails?.sTitle)
            setDescription(cmsDetails?.sDescription)
            setPriority(cmsDetails?.nPriority)
            setDetails(cmsDetails?.sContent)
            setCategory(cmsDetails?.sCategory)
            setContentStatus(cmsDetails?.eStatus)

            if (previousProps?.cmsDetails?.sSlug !== cmsDetails?.sSlug) {
                navigate(`/content-management/content-details/${cmsDetails?.sSlug}`)
            }
        }
    }, [cmsDetails])
    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])

    useEffect(() => {
        setIsCreate(!slug)
        setIsEdit(!!slug)
    }, [slug])
    // set timeout to remove pop up success/error message after given interval
    useEffect(() => {
        modalMessageFunc(modalMessage, setModalMessage, setClose)
    }, [modalMessage])

    useEffect(() => {
        setSubmitDisableButton(submitDisable)
    }, [submitDisable])
    // forvalidate the field and dispatch action
    function onSubmit(e) {
        if (verifyLength(Slug, 1) && verifyLength(Title, 1) && verifyLength(Details, 1) && !errPriority && !errDetails && !errSlug && !errTitle) {
            if (isCreate) {
                const addDataCMS = {
                    Category, Slug, Title, Description, priority, contentStatus, Details, token
                }
                addCMSFunction(addDataCMS)
            } else {
                const updateDataCMS = {
                    Category, cmsId, Slug, Title, Description, priority, contentStatus, Details, token
                }
                updateCMSFunction(updateDataCMS)
            }
        } else {
            seterrSlug(!verifyLength(Slug, 1) ? 'Required field' : '')
            seterrTitle(!verifyLength(Title, 1) ? 'Required field' : '')
            seterrDetails(!verifyLength(Details, 1) ? 'Required field' : '')
        }
    }

    useImperativeHandle(ref, () => ({
        onSubmit
    }))

    function handleChange(event, type) {
        const value = event?.target?.value
        switch (type) {
            case 'Slug':
                if (verifyLength(value, 1)) {
                    seterrSlug(withoutSpace(value) ? 'No space allowed' : '')
                } else {
                    seterrSlug('Required field')
                }
                setSlug(value)
                break
            case 'Title':
                seterrTitle(verifyLength(value, 1) ? '' : 'Required field')
                setTitle(value)
                break
            case 'Category':
                setCategory(value)
                break 
            case 'description':
                setDescription(value)
                break
            case 'Priority':
                // setPriority(value)
                if (isNumber(value) || (!value)) {
                    if (isNumber(value) || value === '') {
                        setErrPriority('')
                        setPriority(value)
                    }
                    // cmsList?.length && cmsList?.map((list) => (list?.nPriority === parseInt(value) && list?.sSlug !== Slug)
                    //     ? setErrPriority('Priority is already exist')
                    //     : setPriority(value))
                }
                break
            case 'Status':
                setContentStatus(value)
                break
            default:
                break
        }
    }

    function onEditorChange(evt, editor) {
        const data = editor?.getData()
        const error = verifyLength(data, 1) ? '' : 'Required field'
        seterrDetails(error)
        setDetails(data)
    }

    return (
        <main className="main-content">
            {!isCreate && isLoading && <Loading />}
            <AlertMessage
                close={close}
                message={message}
                modalMessage={modalMessage}
                status={status}
            />

            <section className="common-form-block">
                <Form>
                    <Row>
                        <Col md={12} xl={12}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="slug">
                                    Slug
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' className={errSlug ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.CMS === 'R'} name="slug" onChange={event => handleChange(event, 'Slug')} placeholder="Slug" defaultValue={Slug} />
                                <p className="error-text">{errSlug}</p>
                            </FormGroup>
                        </Col>
                        {/* <Col md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="category">Category</Label>
                                <Input autoComplete='off' disabled={adminPermission?.CMS === 'R'} name="category" onChange={event => handleChange(event, 'Category')} placeholder="Category" defaultValue={Category} />
                            </FormGroup>
                        </Col>  */}
                    </Row>

                    <Row >
                        <Col className='mt-3' md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="title">
                                    Title
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input autoComplete='off' className={errTitle ? 'league-placeholder-error ' : 'league-placeholder'} disabled={adminPermission?.CMS === 'R'} name="title" onChange={event => handleChange(event, 'Title')} placeholder="Title" defaultValue={Title} />
                                <p className="error-text">{errTitle}</p>
                            </FormGroup>
                        </Col>
                        <Col className='mt-3' md={12} xl={6}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="priority">
                                    Priority
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <Input
                                    autoComplete='off'
                                    className={errPriority ? 'league-placeholder-error ' : 'league-placeholder'}
                                    disabled={adminPermission?.CMS === 'R'}
                                    name="Priority" onChange={event => handleChange(event, 'Priority')}
                                    placeholder="Priority" 
                                    type='number'
                                    id='Priority'
                                    value={priority}
                                />
                                <p className="error-text">{errPriority}</p>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mt-3' md={12} xl={12}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="description">Description</Label>
                                <Input autoComplete='off' className='read-only-class' disabled={adminPermission?.CMS === 'R'} name="description" onChange={event => handleChange(event, 'description')} placeholder="Enter Description" type='textarea' defaultValue={Description} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='mt-3' md={12} xl={12}>
                            <FormGroup className="form-group">
                                <Label className='edit-label-setting' for="status">
                                    Content
                                    {' '}
                                    <RequiredField />
                                </Label>
                                <div className={errDetails ? 'ck-border' : ''}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            placeholder: 'Enter content',
                                            toolbar: {
                                                items: [
                                                    'heading', '|', 'fontSize', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor', '|',
                                                    'imageUpload', '|',
                                                    'bold', 'italic', 'underline', 'strikethrough', '|',
                                                    'alignment', '|', 'numberedList', 'bulletedList', '|',
                                                    'link', 'blockQuote',
                                                    'insertTable', 'mediaEmbed', '|', 'undo', 'redo', '|', 'htmlembed'
                                                ]
                                            },
                                            plugins: [
                                                Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, Font, Underline, Strikethrough, Alignment, List, Image, Link, BlockQuote, Table, MediaEmbed, ImageUpload, ImageBlock, HtmlEmbed,Base64UploadAdapter
                                            ]
                                        }}
                                        data={Details}
                                        disabled={adminPermission?.CMS === 'R'}
                                        onChange={onEditorChange}
                                        onInit={(editor) => {
                                            editor?.ui?.getEditableElement()?.parentElement?.insertBefore(editor?.ui?.view?.toolbar?.element, editor?.ui?.getEditableElement())
                                        }}
                                    />
                                </div>
                                <p className="error-text">{errDetails}</p>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className='p-3'>
                        <div className='radio-button-div'>
                            <Col md={12} xl={12}>
                                <FormGroup className="form-group">
                                    <Label className='edit-label-setting lable-league' for="status">Status</Label>
                                    <div className="d-flex inline-input mt-2 ">
                                        <div className="custom-radio custom-control">
                                            <Input
                                                checked={contentStatus === 'Y'}
                                                disabled={adminPermission?.CMS === 'R'}
                                                id="contentRadio1"
                                                name="contentRadio"
                                                onChange={event => handleChange(event, 'Status')}
                                                type="radio"
                                                defaultValue="Y"
                                                className='custom-control-input me-2'
                                            />
                                            <Label for="contentRadio1">Active</Label>
                                        </div>
                                        <div className="custom-radio custom-control">
                                            <Input
                                                checked={contentStatus !== 'Y'}
                                                disabled={adminPermission?.CMS === 'R'}
                                                id="contentRadio2"
                                                name="contentRadio"
                                                onChange={event => handleChange(event, 'Status')}
                                                type="radio"
                                                value="N"
                                                className='custom-control-input me-2'
                                            />
                                            <Label for="contentRadio2">In Active</Label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </div>
                    </Row>
                </Form>
            </section>
        </main>
    )
})
AddContentForm.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    // isCreate: PropTypes.string,
    // isEdit: PropTypes.string,
    setIsCreate: PropTypes.func,
    setIsEdit: PropTypes.func,
    setSubmitDisableButton: PropTypes.func,
    // adminPermission: PropTypes.string,
    Slug: PropTypes.string,
    setSlug: PropTypes.func,
    Category: PropTypes.string,
    setCategory: PropTypes.func,
    Title: PropTypes.string,
    setTitle: PropTypes.func,
    priority: PropTypes.number,
    setPriority: PropTypes.func,
    Details: PropTypes.string,
    setDetails: PropTypes.func
}
export default AddContentForm