import React, { useEffect, useState } from 'react'
import { Row, Col, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
function DepositDetails ({ dashBoardData }) {
  const [dayFilter, setDayFilter] = useState('month')
  const navigate = useNavigate()
  const [detailsCard, setDetailsCard] = useState([])

  useEffect(() => {
    setDetailsCard([
      { name: 'Total Deposits', key: 'DEPOSIT', value: dashBoardData?.oDeposit || 0 },
      { name: 'Total Withdraw', key: 'WITHDRAW', value: dashBoardData?.oWithdraw || 0 },
      { name: 'Total Commission', key: 'COMMISSION', value: dashBoardData?.oAdminCommission || 0 },
      { name: 'Total Revenue', key: 'REVENUE', value: dashBoardData?.oAdminPlatformFees || 0 },
      { name: 'Total Business', key: 'BUSINESS', value: dashBoardData?.oPlay || 0 },
      { name: 'Total Trade', key: 'TRADE', value: dashBoardData?.oTrade || 0 }
    ])
  }, [dashBoardData])

  function onFiltering (event) {
    setDayFilter(event.target.value)
  }
  const handleOnClickEvent = (tab) =>{
    const currentDate = new Date();
    let firstDay=''
    let lastDay=''
    if(dayFilter==='month'){
      firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      lastDay.setHours(23, 59, 59, 999);
    }else if(dayFilter==='week'){
      const dayOfWeek = currentDate.getDay(); 
      firstDay = new Date(currentDate);
      firstDay.setDate(currentDate.getDate() - dayOfWeek); 
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate);
      lastDay.setDate(currentDate.getDate() + (6 - dayOfWeek)); 
      lastDay.setHours(23, 59, 59, 999); 
    }else{
      firstDay = new Date(currentDate);
      firstDay.setHours(0, 0, 0, 0);
      lastDay = new Date(currentDate);
      lastDay.setHours(23, 59, 59, 999);
    }
    firstDay = new Date(firstDay)?.toISOString();
    lastDay = new Date(lastDay)?.toISOString();
    if(tab==='WITHDRAW'){
    navigate(`/transaction/withdraw-management?datefrom=${firstDay}&dateto=${lastDay}&status=S`)
    }else if(tab==='DEPOSIT')
    navigate(`/transaction/deposit-management?datefrom=${firstDay}&dateto=${lastDay}&status=S`)
  }
  return (
    <>
      <div className='dashboard-deposit'>
        <div className='deposit-heading'>
          <h3 className='mb-0 '>Details</h3>
          <Input
            className='day-filter'
            id="dayFilter"
            name="dayFilter"
            onChange={(event) => onFiltering(event)}
            type="select"
            value={dayFilter}
          >
            <option value="today">Today</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </Input>
        </div>
        <Row className='dashboard-row'>
          {detailsCard && detailsCard?.length > 0 && detailsCard.map((o, i) => {
            return (
                <Col className='deposit-col' md={6} xl={3}>
                  <div className='common-box-dashboard'>
                    <div className='common-box-dashboard-div'>
                      <p className='text-label-deposit'>
                        {' '}
                        <span>{o?.name}</span>
                      </p>
                    <button className='w-100 ' onClick={ ()=>handleOnClickEvent(o?.key)} style={{ border:"none",textAlign:'left',background:'transparent' }}>
                      <h2 className='dashboard-heading mb-0'>
                        {' '}
                        {(dayFilter === 'today' ? o?.value?.nToday?.toString()?.includes('.') ? o?.value?.nToday?.toFixed(2) : o?.value?.nToday : dayFilter === 'week' ? o?.value?.nWeek?.toString()?.includes('.') ? o?.value?.nWeek?.toFixed(2) : o?.value?.nWeek : o?.value?.nMonth?.toString()?.includes('.') ? o?.value?.nMonth?.toFixed(2) : o?.value?.nMonth) || 0}
                        {' '}
                      </h2>
                      </button>
                    </div>
                  </div>
                </Col>
            )
          })}
        </Row>
        {/* <Col className='free-contest-col' md={6} xl={3}>
          <div className='common-box-dashboard'>
            <div className='common-box-dashboard-div'>
              <p className='text-label-free-content'>
                {' '}
                <span> Free Contest </span>
              </p>
              <h2 className='dashboard-heading mb-0'>
                {' '}
                {dayFilter === 'today' ? dashBoardData?.oContest?.oFree?.nToday : dayFilter === 'week' ? dashBoardData?.oContest?.oFree?.nWeek : dashBoardData?.oContest?.oFree?.nMonth}
                {' '}
              </h2>
            </div>
          </div>
        </Col>
        <Col className='paid-contest-col' md={6} xl={3}>
          <div className='common-box-dashboard '>
            <div className='common-box-dashboard-div'>
              <p className='text-label-paid-content'>
                {' '}
                <span> Paid Contest </span>
              </p>
              <h2 className='dashboard-heading mb-0'>
                {' '}
                {dayFilter === 'today' ? dashBoardData?.oContest?.oPaid?.nToday : dayFilter === 'week' ? dashBoardData?.oContest?.oPaid?.nWeek : dashBoardData?.oContest?.oPaid?.nMonth}
                {' '}
              </h2>
            </div>
          </div>
        </Col> */}
      </div>
    </>
  )
}

DepositDetails.propTypes = {
  dashBoardData: PropTypes.object
}
export default DepositDetails
