import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import RequiredField from '../../../components/RequiredField'
import MainHeading from '../Component/MainHeading'
import { encryption, modalMessageFunc, verifyPassword } from '../../../helpers/helper'
import { changeAdminName, changeAdminPassword } from '../../../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'

const EditProfile = () => {
  const dispatch = useDispatch()
  const adminDetails = useSelector(state => state?.auth?.adminData)
  const [name, setName] = useState(adminDetails?.sName || '')
  const [errName, setErrName] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errCurrentPassword, setErrCurrentPassword] = useState('')
  const [errNewPassword, setErrNewPassword] = useState('')
  const [errConfirmPassword, setErrConfirmPassword] = useState('')
  // const [toastMessage, setToastMessage] = useState({})
  // const [loading, setLoading] = useState(false)
  const resMessage = useSelector((state) => state?.auth?.resMessage)
  const resStatus = useSelector((state) => state?.auth?.resStatus)
  const loading = useSelector((state) => state?.auth?.isLoading)
  const [modalMessage, setModalMessage] = useState(true)
  const [close, setClose] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const previousProps = useRef({ resMessage }).current

  useEffect(() => {
    if (previousProps.resMessage !== resMessage) {
      if (resMessage || resStatus) {
        setMessage(resMessage)
        setStatus(resStatus)
        setModalMessage(true)
      } else {
        setMessage(resMessage)
        setStatus(resStatus)
        setModalMessage(true)
      }
      // setLoading(false)
      // setName('')
      // setCurrentPassword('')
      // setConfirmPassword('')
      // setNewPassword('')
    }
    // setName('')
    setCurrentPassword('')
    setConfirmPassword('')
    setNewPassword('')
    // setLoading(false)
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resMessage, resStatus, loading])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
}, [modalMessage])

  useEffect(() => {
    if (adminDetails) {
      setName(adminDetails?.sName || '')
    }
  }, [adminDetails])

  function handleChange(event, eType) {
    switch (eType) {
      case 'name':
        if (!event?.target?.value) {
          setErrName('Required field')
        } else if (event?.target?.value.trimStart()?.length === 0) {
          setErrName('No Initial Space Allowed')
        } else {
          setErrName('')
        }
        setName(event?.target?.value?.trimStart())
        break
      case 'CurrentPassword':
        if (verifyPassword(event?.target?.value)) {
          setErrCurrentPassword('')
        } else {
          setErrCurrentPassword('Must contain minimum 5 characters and maximum 14 characters')
        }
        setCurrentPassword(event?.target?.value)
        break
      case 'NewPassword':
        if (verifyPassword(event?.target?.value)) {
          setErrNewPassword('')
        } else {
          setErrNewPassword('Must contain minimum 5 characters and maximum 14 characters')
        }
        setNewPassword(event?.target?.value)
        break
      case 'ConfirmPassword':
        if (newPassword !== event?.target?.value) {
          setErrConfirmPassword('New password and confirm password do not match')
        } else {
          setErrConfirmPassword('')
        }
        setConfirmPassword(event?.target?.value)
        break
      default:
        break
    }
  }

  const handleNameSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!name) {
        setErrName('Name is required')
        return
      }
      if (errName) {
        return
      }
      if (name) {
        // setLoading(true)
        dispatch(changeAdminName(name))
      }
    } catch (error) {
      console.log(error)
      // setLoading(false)
    }
  }

  const handleChangePassSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!newPassword || !confirmPassword || !currentPassword) {
        setErrCurrentPassword(currentPassword ? '' : 'Current password is required')
        setErrNewPassword(newPassword ? '' : 'New password is required')
        setErrConfirmPassword(
          !confirmPassword
            ? 'Confirm password is required'
            : confirmPassword && confirmPassword !== newPassword
            ? 'New password and confirm password do not match'
            : ''
        )
        return
      }
      if (errCurrentPassword || errNewPassword || errConfirmPassword) {
        return
      }
      if (newPassword === confirmPassword && newPassword !== '' && confirmPassword !== '' && currentPassword !== '') {
        // setLoading(true)
        const encryptionCurrentPassword = encryption(currentPassword)
        const encryptionNewPassword = encryption(newPassword)
        dispatch(changeAdminPassword(encryptionCurrentPassword, encryptionNewPassword))
      } else {
        setErrConfirmPassword('New password and confirm password do not match')
      }
    } catch (error) {
      console.log(error)
      // setLoading(false)
      //   setToastMessage({
      //     message: error?.response?.data?.message || 'Something went wrong',
      //     status: false,
      //   });
    } finally {
      // setLoading(false)
    }
  }

  return (
    <Fragment>
      <MainHeading heading='Edit Profile' />
      {loading && <Loading />}
      {/* {(message || modalMessage) && */}
        <AlertMessage
          close={close}
          message={message}
          modalMessage={modalMessage}
          status={status}
        />
      {/* } */}
      <div className='edit-profile'>
        <div className='name-card'>
          <Form onSubmit={handleNameSubmit}>
            {/* <div className='px-4 pt-4'>
              <h6>Your Name : {adminDetails?.sName}</h6>
            </div> */}
            <FormGroup className='p-4 pb-0 form-group'>
              <Label className='edit-label-setting' for='NotificationTitle'>
                Your Name <RequiredField />
              </Label>
              <Input
                className={errName ? 'league-placeholder-error' : 'league-placeholder'}
                // disabled={adminPermission?.NOTIFICATION === 'R'}
                onChange={(event) => handleChange(event, 'name')}
                placeholder='Enter Name'
                type='text'
                value={name}
              />
              <p className='error-text'>{errName}</p>
            </FormGroup>
            <div className='p-4'>
              <Button className='theme-btn w-100 ' type='submit'>
                Change Name
              </Button>
            </div>
          </Form>
        </div>

        <div className='name-card'>
          {/* <ChangePassword /> */}
          <section className='change-pass-card'>
            <div className='d-flex inline-input' />
            <Form onSubmit={handleChangePassSubmit}>
              <Row className='mt-3'>
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label for='CurrentPassword'>
                      Current Password
                      <RequiredField />
                    </Label>
                    <Input
                      className={errCurrentPassword ? 'league-placeholder-error' : 'league-placeholder'}
                      //   disabled={adminPermission?.SUBADMIN === 'R'}
                      id='CurrentPassword'
                      onChange={(event) => handleChange(event, 'CurrentPassword')}
                      placeholder='Enter Current Password'
                      type='password'
                      value={currentPassword}
                    />
                    <p className='error-text'>{errCurrentPassword}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label for='NewPassword'>
                      New Password
                      <RequiredField />
                    </Label>
                    <Input
                      className={errNewPassword ? 'league-placeholder-error' : 'league-placeholder'}
                      //   disabled={adminPermission?.SUBADMIN === 'R'}
                      id='NewPassword'
                      onChange={(event) => handleChange(event, 'NewPassword')}
                      placeholder='Enter New Password'
                      type='password'
                      value={newPassword}
                    />
                    <p className='error-text'>{errNewPassword}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={12} xl={12}>
                  <FormGroup className='form-group'>
                    <Label for='ConfirmPassword'>
                      Confirm New Password
                      <RequiredField />
                    </Label>
                    <Input
                      className={errConfirmPassword ? 'league-placeholder-error' : 'league-placeholder'}
                      //   disabled={adminPermission?.SUBADMIN === 'R'}
                      id='ConfirmPassword'
                      onChange={(event) => handleChange(event, 'ConfirmPassword')}
                      placeholder='Enter Confirm New Password'
                      type='password'
                      value={confirmPassword}
                    />
                    <p className='error-text'>{errConfirmPassword}</p>
                  </FormGroup>
                </Col>
              </Row>
              <div className='p-2 pb-3'>
                <Button className='theme-btn w-100 ' type='submit'>
                  Change Password
                </Button>
              </div>
            </Form>
          </section>
        </div>
      </div>
    </Fragment>
  )
}

export default EditProfile
