import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'

import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import RequiredField from '../../../components/RequiredField'
import calendarIcon from '../../../assets/images/calendar.svg'

import { modalMessageFunc, verifyLength } from '../../../helpers/helper'
import { getUrl } from '../../../actions/url'
import getCategoryList from '../../../api/category/getCategoryList'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import getSubCategoryList from '../../../api/category/getSubCategoryList'
import DatePicker from 'react-datepicker'
import { addEvent, getAdminBuyCommission, getEventDetails, updateEventDetails } from '../../../actions/events'

const AddEventForm = forwardRef((props, ref) => {
  const { isCreate, setIsEdit, setIsCreate, adminPermission, setSubmitDisableButton } = props
  const { id } = useParams()
  
  const [eventId, setEventId] = useState('')
  const [eventName, setEventName] = useState('')
  const [eventNameErr, setEventNameErr] = useState('')
  const [eventDesc, setEventDesc] = useState('')
  const [eventCategory, setEventCategory] = useState('')
  const [categoryErr, setCategoryErr] = useState('')
  const [sentiment, setSentiment] = useState('NO')
  const [eventSubCategory, setEventSubCategory] = useState('')
  const [subCatErr] = useState('')
  const [adminCommissionState, setAdminCommissionState] = useState(0)
  const [minBuyQty, setMinBuyQty] = useState(1)
  const [minBuyQtyErr, setMinBuyQtyErr] = useState('')
  const [maxBuyQty, setMaxBuyQty] = useState(10000)
  const [maxBuyQtyErr, setMaxBuyQtyErr] = useState('')
  const [labelForYes, setLabelForYes] = useState('Yes')
  const [labelForYesErr, setLabelForYesErr] = useState('')
  const [labelForNo, setLabelForNo] = useState('No')
  const [labelForNoErr, setLabelForNoErr] = useState('')
  const [initialQuatityForYes, setInitialQuantityForYes] = useState(10000)
  const [initialQTYForYesErr, setInitialQTYForYesErr] = useState('')
  const [initialQuatityForNo, setInitialQuantityForNo] = useState(10000)
  const [initialQTYForNoErr, setInitialQTYForNoErr] = useState('')
  const [bonus, setBonus] = useState(0)
  const [currentPriceForYes, setCurrentPriceForYes] = useState(4)
  const [currentPriceForYesErr, setCurrentPriceForYesErr] = useState('')
  const [currentPriceForNo, setCurrentPriceForNo] = useState(5)
  const [currentPriceForNoErr, setCurrentPriceForNoErr] = useState('')
  const [totalPrice, setTotalPrice] = useState(10)
  const [totalPriceErr, setTotalPriceErr] = useState('')
  const [priceDiff, setPriceDiff] = useState(0.5)
  const [priceDiffErr, setPriceDiffErr] = useState('')

  const [eventCategoryId, setEventCategoryId] = useState('')
  // const [eventSubCatList, setEventSubCatList] = useState('')
  const [isOpenSubCat, setIsOpenSubCat] = useState(false)
  const [isSentimentAdded, setIsSentimentAdded] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startDateErr, setStartDateErr] = useState('')
  const [endDate, setEndDate] = useState('');
  const [endDateErr, setEndDateErr] = useState('')
  const [eventTags, setEventTags] = useState('');
  const [eventTagsErr, setEventTagsErr] = useState('');
  // const [tags, setTags] = useState([])

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [close, setClose] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const eventDetails = useSelector(state => state?.events?.eventDetails)
  const resStatus = useSelector(state => state?.events?.resStatus)
  const resMessage = useSelector(state => state?.events?.resMessage)
  const adminCommission = useSelector(state => state?.events?.adminCommission)
  const previousProps = useRef({ resStatus, resMessage, eventDetails, adminCommission })?.current
  const [modalMessage, setModalMessage] = useState(false)
  const { data: categoryList } = useQuery({
    queryKey: ['getCategoryList', 0, 50, '', 'Y'],
    queryFn: () => getCategoryList(0, 50, '', 'Y'),
    select: (res) => res?.data?.data?.results
  })

  const {
    data: subCategoryList,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['getSubCategoryList', 0, 10, eventCategoryId,'Y'],
    queryFn: ({ pageParam = 0 }) => getSubCategoryList(pageParam, 10, eventCategoryId,'Y'),
    getNextPageParam: (lastPage) => {
      const { total, results } = lastPage?.data?.data;
      const nextPage = results?.length ? results?.length : null;
      return nextPage && results.length < total ? nextPage : undefined;
    },
    select: (res) => res?.pages?.flatMap(page => page?.data?.data?.results) || [],
    keepPreviousData: true,
  });
  const submitDisable = () => {
    const isAdd = !eventDetails
    const commonConditions = eventName === '' || eventCategory === '' || eventSubCategory === '' || startDate === '' || endDate === '' || eventTags === '' || minBuyQty === '' || maxBuyQty === '' || labelForYes==='' || initialQuatityForYes==='' || labelForNo==='' || initialQuatityForNo ===''  || minBuyQtyErr!=='' || !priceDiff || !totalPrice || totalPriceErr !== '' || priceDiffErr !== '' || currentPriceForNoErr !== '' || currentPriceForYesErr !== ''
    const totalCheck = 0 < (Number(currentPriceForYes) + Number(currentPriceForNo)) && 
    (Number(currentPriceForYes) + Number(currentPriceForNo)) <= totalPrice
    const priceDiffCheck = 0 < Number(priceDiff) && Number(priceDiff) < (Number(totalPrice) * 0.75)
    if (isAdd) {
      return commonConditions || !totalCheck || !priceDiffCheck
      // return commonConditions
    }
    const startDate1 = new Date(startDate);
    const hasStarted = startDate1 < new Date();
    return commonConditions || hasStarted || !totalCheck || !priceDiffCheck
    // return commonConditions || hasStarted
  }
  useEffect(() => {
    if (adminCommission) {
      setAdminCommissionState(adminCommission?.nAmount)
    }
  }, [adminCommission])
  useEffect(() => {
    if (id) {
      dispatch(getEventDetails({ Id: id }))
      setEventId(id)
      setIsCreate(false)
      setLoading(true)
    } else {
      setIsEdit(true)
    }
    dispatch(getAdminBuyCommission())
    dispatch(getUrl('media'))
  }, [])
  useEffect(() => {
    if (previousProps?.eventDetails !== eventDetails) {
      if (eventDetails) {
        setEventName(eventDetails?.sName)
        setEventDesc(eventDetails?.sDescription)
        setEventCategoryId(eventDetails?.oCategory?._id)
        setEventCategory(eventDetails?.oCategory?.eCategoryType)
        if (eventDetails?.iSubCategoryId) {
          setIsOpenSubCat(true)
          setEventSubCategory({
            value: eventDetails?.oSubCategory?._id,
            label: eventDetails?.oSubCategory?.sName
          })
        }
        setStartDate(new Date(eventDetails?.dStartDate))
        setEndDate(new Date(eventDetails?.dEndDate))
        if (eventDetails?.aTags?.length) {
          setEventTags(eventDetails?.aTags.join('\n'))
          // setTags(eventDetails.aTags)
        }
        setTotalPrice(eventDetails?.nTotalPrice)
        setPriceDiff(eventDetails?.nPriceDiff)
        setIsSentimentAdded(eventDetails?.bIsSentimentAdded || false)
        setAdminCommissionState(eventDetails?.nAdminBuyCommission || 0)
        setLabelForYes(eventDetails?.aAnswerOptions[0]?.sLabel)
        setInitialQuantityForYes(eventDetails?.aAnswerOptions[0]?.nInitialQty || 0)
        setLabelForNo(eventDetails?.aAnswerOptions[1]?.sLabel)
        setInitialQuantityForNo(eventDetails?.aAnswerOptions[1]?.nInitialQty || 0)
        setMinBuyQty(eventDetails?.oUserConfig?.nMinBuyQty || 0)
        setMaxBuyQty(eventDetails?.oUserConfig?.nMaxBuyQty || 0)
        setBonus(eventDetails?.nBonusUtil || 10)
        setSentiment(eventDetails?.aAnswerOptions[0]?.bIsSentiment===true ? 'YES' : 'NO')
        setCurrentPriceForNo(eventDetails?.aAnswerOptions[1]?.nCurrentPrice || 0)
        setCurrentPriceForYes(eventDetails?.aAnswerOptions[0]?.nCurrentPrice || 0)
        setLoading(false)
      }
    }
    return () => {
      previousProps.eventDetails = eventDetails
    }
  }, [eventDetails])
  useEffect(() => {
    setSubmitDisableButton(submitDisable)
  }, [submitDisable])
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        if (resStatus && isCreate) {
          navigate('/events/event-management/', { state: { message: resMessage } })
        } else {
          if (resStatus) {
            setIsEdit(false)
            dispatch(getEventDetails({ Id: id }))
          }
          setModalMessage(true)
        }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // for handle onChange event
  function handleChange(event, type) {
    const value = event?.target?.value
    switch (type) {
      case 'Name':
        if (verifyLength(value, 1)) {
          setEventNameErr('')
        } else {
          setEventNameErr('Required field')
        }
        setEventName(value)
        break
      case 'Description':
        setEventDesc(value)
        break
      case 'categoryType': 
        if (!value) {
          setCategoryErr('Required field')
        } else {
          setCategoryErr('')
        }
        setIsOpenSubCat(true)
        const selectedIndex = event?.target?.selectedIndex
        const selectedOption = event?.target?.options[selectedIndex]
        const selectedKey = selectedOption.getAttribute('data-key')
        setEventCategoryId(selectedKey)
        setEventCategory(value)
        break
      case 'startDate':
        if (!event) {
          setStartDateErr('Required field')
        } else {
          setStartDateErr('')
        }
        setStartDate(event);
        if (endDate && event > endDate) {
          setEndDate('');
        }
        break
      case 'endDate':
        if (!event) {
          setEndDateErr('Required field')
        } else {
          setEndDateErr('')
        }
        setEndDate(event)
        break
      case 'tags':
        if (!value) {
          setEventTagsErr('Required field')
        } else {
          setEventTagsErr('')
        }
        setEventTags(value)
        break
      case 'adminCommission':
        setAdminCommissionState(value)
        break
      case 'labelForYes':
        if (!value) {
          setLabelForYesErr('Required Field')
        } else {
          setLabelForYesErr('')
        }
        setLabelForYes(value)
        break
      case 'labelForNo':
        if (!value) {
          setLabelForNoErr('Required Field')
        } else {
          setLabelForNoErr('')
        }
        setLabelForNo(value)
        break
      case 'minBuyQty':
        if (!event?.target?.value) {
          setMinBuyQtyErr('Required field')
        } else if(Number(value)===Number(maxBuyQty)) {
          setMinBuyQtyErr("Min QTY and Max QTY can't be same")
        }else if(Number(value)>Number(maxBuyQty)){
          setMinBuyQtyErr("Min QTY Can't be greater than Max QTY")
        }else{
          setMinBuyQtyErr('')
        }
        setMinBuyQty(value)
        break
      case 'maxBuyQty':
        if (!value) {
          setMaxBuyQtyErr('Required field')
        } else if(Number(value)===Number(minBuyQty)) {
          setMaxBuyQtyErr("Min QTY and Max QTY can't be same")
        } else if(Number(value)===Number(minBuyQty)) {
          setMinBuyQtyErr("")
          setMaxBuyQtyErr("")
        } else {
          setMaxBuyQtyErr('')
        }
        setMaxBuyQty(value)
        break
      case 'initialQuantityForYes':
        if (!value) {
          setInitialQTYForYesErr('Required field')
        } else {
          setInitialQTYForYesErr('')
        }
        setInitialQuantityForYes(value)
        break
      case 'initialQuantityForNo':
        if (!value) {
          setInitialQTYForNoErr('Required field')
        } else {
          setInitialQTYForNoErr('')
        }
        setInitialQuantityForNo(value)
        break
      case 'bonus':
        setBonus(value)
        break
      case 'bIsSentimentAdded':
        setIsSentimentAdded(event?.target?.checked)
        break
      case 'sentiment':
        setSentiment(value)
        break
      case 'currentPriceForYes':
        if (!value) {
          setCurrentPriceForYesErr('Required Field')
        } else if (Number(value) + Number(currentPriceForNo) > totalPrice) {
          setCurrentPriceForYesErr('Yes/No price <= total price')
        } else {
          setCurrentPriceForYesErr('')
          setTotalPriceErr('')
        }
        setCurrentPriceForYes(value)
        break
      case 'currentPriceForNo':
        if (!value) {
          setCurrentPriceForNoErr('Required Field')
        } else if (Number(value) + Number(currentPriceForYes) > totalPrice) {
          setCurrentPriceForNoErr('Yes/No price <= total price')
        } else {
          setCurrentPriceForNoErr('')
        }
        setCurrentPriceForNo(value)
        break
      case 'totalPrice':
        if (!value) {
          setTotalPriceErr('Required Field')
        } else if (value <= 0) {
          setTotalPriceErr('Total Price must be greater than 0')
        } else if (value > 1000) {
          setTotalPriceErr('Max 1000')
        } else if (Number(value) < (Number(currentPriceForYes) + Number(currentPriceForNo))) {
          setTotalPriceErr('Total price must be greater than or equal to the sum of Yes/No prices')
          setCurrentPriceForYesErr('Yes/No price <= total price')
          // setCurrentPriceForNoErr('Yes/No price <= total price')
        } else {
          setTotalPriceErr('')
          if (Number(currentPriceForYes) + Number(currentPriceForNo) > value) {
            setCurrentPriceForYesErr('Yes/No price <= total price')
            // setCurrentPriceForNoErr('Yes/No price <= total price')
          } else {
            setCurrentPriceForYesErr('')
            // setCurrentPriceForNoErr('')
          }
        }
        setTotalPrice(value)
        break
      case 'priceDiff':
        if (!value) {
          setPriceDiffErr('Required Field')
        } else if (value <= 0) {
          setPriceDiffErr('Price diff must be greater than 0')
        } else if ((Number(totalPrice) * 0.75) <= value) {
          setPriceDiffErr('Price < 75% of total price')
        } else {
          setPriceDiffErr('')
        }
        setPriceDiff(value)
        break
      default:
        break
    }
  }
  function onSubmit() {
    const newArr = eventTags.split('\n').map(tag => tag.trim()).filter(tag => tag);
    let verify = false
    verify = (verifyLength(eventName, 1) && eventCategory && eventSubCategory && startDate && endDate && newArr && totalPrice && priceDiff && !totalPriceErr && !priceDiffErr)
    const quantityRange = generateRanges(Number(minBuyQty), Number(maxBuyQty))
    if (verify) {
      if (isCreate) {
        const addEventData = {
          eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags:newArr, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType: adminCommission?.eAmountType, initialQuatityForYes, initialQuatityForNo,bonus,sentiment,currentPriceForNo,currentPriceForYes, bIsSentimentAdded: isSentimentAdded, totalPrice, priceDiff
        }
        dispatch(addEvent(addEventData))
      } else {
        const updateEventData = {
          eventId, eventName, eventDesc, eventCategoryId, eventSubCategory, startDate, endDate, tags:newArr, adminCommissionState, labelForYes, labelForNo, minBuyQty, maxBuyQty, quantityRange, commissionType: adminCommission?.eAmountType, initialQuatityForYes, initialQuatityForNo,bonus,sentiment,currentPriceForNo,currentPriceForYes, bIsSentimentAdded: isSentimentAdded, totalPrice, priceDiff
        }
        dispatch(updateEventDetails(updateEventData))
      }
      setLoading(true)
    } 
  }
  useImperativeHandle(ref, () => ({
    onSubmit
  }))
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && eventTags.trim()) {
  //     const newTags = eventTags.split('\n').map(tag => tag.trim()).filter(tag => tag);
  //     if (newTags.length) {
  //       setTags(prevTags => [
  //         ...prevTags,
  //         ...newTags.filter(tag => !prevTags.includes(tag))
  //       ]);
  //     }
  //   }
  // };
  const generateRanges = (min, max) => {
    const minS = parseInt(min, 10);
    const maxS = parseInt(max, 10);
    const length = 5;
    const step = (maxS - minS) / length;
  
    const newRanges = Array.from({ length }, (_, index) => {
      const start = minS + index * step;
      const end = minS + (index + 1) * step;
      return {
        nMin: Math.round(start),
        nMax: Math.round(index === length - 1 ? maxS : end),
      };
    });
  
    return newRanges;
  };

  const options = subCategoryList?.map(subCat => ({
    value: subCat._id,
    label: `${subCat?.sName}`,
  })) || [];
  const ExampleCustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt="calendar" src={calendarIcon} />
      <Input ref={ref} className='mx-2 range' placeholder={placeholder} readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput
  return (
    <main className="main-content">
      <section className="common-detail">
        <AlertMessage
          close={close}
          message={message}
          modalMessage={modalMessage}
          status={status}
        />
        {loading && <Loading />}
        <section className="common-form-block">
          <Form>
            <Row className='mt-3'>
              <Col md={12} xl={12}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="place">
                    Event Name
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={eventNameErr ? 'league-placeholder-error' : 'form-control'}
                    disabled={adminPermission?.OT_EVENT === 'R'}
                    name="eventName"
                    onChange={event => handleChange(event, 'Name')}
                    type="text"
                    defaultValue={eventName}
                  />
                  <p className="error-text">{eventNameErr}</p>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row>
            <Col md={12} xl={12}>
              <FormGroup className='form-group'>
                <Label className='edit-label-setting' for="place">
                  Event Description
                  {' '}
                </Label>
                <Input
                  className={'form-control'}
                  disabled={adminPermission?.OT_EVENT === 'R'}
                  name="eventDescription"
                  onChange={event => handleChange(event, 'Description')}
                  type="textarea"
                  defaultValue={eventDesc}
                />
              </FormGroup>
            </Col>
          </Row> */}
            <Row>
              <Col md={12} xl={6}>
                <FormGroup className='form-group mt-2'>
                  <Label className='edit-label-setting' for="place">
                    Category Type
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={categoryErr ? 'league-placeholder-error' : 'form-control'}
                    disabled={adminPermission?.OT_EVENT === 'R'}
                    name="category"
                    onChange={event => handleChange(event, 'categoryType')}
                    type="select"
                    value={eventCategory}
                  >
                    <option value=''>Select Category</option>
                    {categoryList?.map((category) => {
                      return <option value={category?.eCategoryType} data-key={category?._id}>{category?.sName}</option>
                    })}
                  </Input>
                  <p className="error-text">{categoryErr}</p>
                </FormGroup>
              </Col>
              {isOpenSubCat &&
                <Col md={12} xl={6}>
                  <FormGroup className='form-group mt-2'>
                    <Label className='edit-label-setting' for="subCat">
                      Sub Category
                      {' '}
                      <RequiredField />
                    </Label>
                    <Select
                      className='season-select'
                      options={options}
                      isDisabled={adminPermission?.OT_EVENT === 'R'}
                      onMenuScrollToBottom={() => {
                        if (hasNextPage && !isFetching) {
                          fetchNextPage();
                        }
                      }}
                      onChange={(selectedOption) => setEventSubCategory(selectedOption)}
                      placeholder='Select Sub Category'
                      value={eventSubCategory}
                    />
                    <p className="error-text">{subCatErr}</p>
                  </FormGroup>
                </Col>
              }
            </Row>
            <Row className='event-form mt-2'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="place">
                    Select Start Date
                    {' '}
                    <RequiredField />
                  </Label>
                  <DatePicker
                    className={startDateErr ? 'league-placeholder-error' : 'form-control'}
                    customInput={<ExampleCustomInput placeholder="Select Start Date" />}
                    dropdownMode="select"
                    isClearable={true}
                    minDate={new Date()}
                    minTime={new Date()}
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select Start Date and Time"
                    selectsStart
                    showMonthDropdown
                    showYearDropdown
                    onChange={(date) => handleChange(date, 'startDate')}
                    selected={startDate}
                  />
                  <p className="error-text">{startDateErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="place">
                    Select End Date
                    {' '}
                    <RequiredField />
                  </Label>
                  <DatePicker
                    className={endDateErr ? 'league-placeholder-error' : 'form-control'}
                    customInput={<ExampleCustomInput placeholder="Select End Date" />}
                    dropdownMode="select"
                    isClearable={true}
                    minDate={startDate || new Date()} // Ensure end date is after start date
                    minTime={startDate && endDate && startDate.toDateString() === endDate.toDateString() ? startDate : new Date(0, 0, 0, 0, 0)}
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select End Date and Time"
                    selectsEnd
                    showMonthDropdown
                    showYearDropdown
                    onChange={(date) => handleChange(date, 'endDate')}
                    selected={endDate}
                    disabled={!startDate} // Disable if start date is not selected
                  />
                  <p className="error-text">{endDateErr}</p>
                </FormGroup>
              </Col>
            </Row>

            <Row className='mt-2'>
              <Col md={12} xl={12}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for="place">
                    Tags
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={eventTagsErr ? 'league-placeholder-error' : 'form-control'}
                    type="textarea"
                    placeholder="Enter a tag and press Enter"
                    defaultValue={eventTags}
                    onChange={(e) => handleChange(e, 'tags')}
                  // onKeyDown={handleKeyDown}
                  />
                  <p className="error-text">{eventTagsErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='commission'>
                    Admin Commission %
                    {' '}
                  </Label>
                  <Input
                    type='number'
                    value={adminCommissionState}
                    onChange={(e) => handleChange(e, 'adminCommission')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Label for Yes Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={labelForYesErr ? 'league-placeholder-error' : 'form-control'}
                    type='text'
                    value={labelForYes}
                    placeholder='Enter Label'
                    onChange={(e) => handleChange(e, 'labelForYes')}
                  />
                  <p className="error-text">{labelForYesErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Label for No Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={labelForNoErr ? 'league-placeholder-error' : 'form-control'}
                    type='text'
                    value={labelForNo}
                    placeholder='Enter Label'
                    onChange={(e) => handleChange(e, 'labelForNo')}
                  />
                  <p className="error-text">{labelForNoErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Initial Quantity for Yes Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={initialQTYForYesErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={initialQuatityForYes}
                    placeholder='Enter Initial Quantity for Yes option'
                    onChange={(e) => handleChange(e, 'initialQuantityForYes')}
                  />
                  <p className="error-text">{initialQTYForYesErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Initial Quantity for No Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={initialQTYForNoErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={initialQuatityForNo}
                    placeholder='Enter Initial Quantity for No option'
                    onChange={(e) => handleChange(e, 'initialQuantityForNo')}
                  />
                  <p className="error-text">{initialQTYForNoErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='totalPrice'>
                    Total Price
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={totalPriceErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={totalPrice}
                    placeholder='Enter Total Price for options'
                    onChange={(e) => handleChange(e, 'totalPrice')}
                    min={0}
                    max={1000}
                  />
                  <p className="error-text">{totalPriceErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='priceDiff'>
                    Price Difference
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={priceDiffErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={priceDiff}
                    placeholder='Enter Price diff for options'
                    onChange={(e) => handleChange(e, 'priceDiff')}
                    min={0}
                  />
                  <p className="error-text">{priceDiffErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Price for Yes Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={currentPriceForYesErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={currentPriceForYes}
                    placeholder='Enter Current Price for Yes option'
                    onChange={(e) => handleChange(e, 'currentPriceForYes')}
                    min={0}
                  />
                  <p className="error-text">{currentPriceForYesErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Price for No Option
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={currentPriceForNoErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={currentPriceForNo}
                    placeholder='Enter Current Price for No option'
                    onChange={(e) => handleChange(e, 'currentPriceForNo')}
                    min={0}
                  />
                  <p className="error-text">{currentPriceForNoErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Min Buy Quantity
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={minBuyQtyErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={minBuyQty}
                    placeholder='Enter Min Buy Quantity'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || parseInt(value) > 0) {
                        handleChange(e, 'minBuyQty');
                      }
                    }}
                    // onChange={(e) => handleChange(e, 'minBuyQty')}
                    min="1"
                  />
                  <p className="error-text">{minBuyQtyErr}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Max Buy Quantity
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={maxBuyQtyErr ? 'league-placeholder-error' : 'form-control'}
                    type='number'
                    value={maxBuyQty}
                    placeholder='Enter Max Buy Quantity'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || parseInt(value) > 0) {
                        handleChange(e, 'maxBuyQty')
                      }
                    }}
                    // onChange={(e) => handleChange(e, 'maxBuyQty')}
                    min="1"
                  />
                  <p className="error-text">{maxBuyQtyErr}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-2'>
              {/* <Col md={6} xl={6} className='mt-4'>
                <FormGroup className='form-group' switch>
                  <div className="d-flex inline-input mt-2">
                    <div className='custom-radio custom-control'>
                      <Label for="ActiveOffer">Is Sentiment Added</Label>
                      <Input
                        checked={isSentimentAdded}
                        id={'bIsSentimentAdded'}
                        name='bIsSentimentAdded'
                        onChange={event => handleChange(event, 'bIsSentimentAdded')}
                        type='switch'
                        className='custom-control-input me-2'
                      />
                    </div>
                  </div>
                </FormGroup>
              </Col> */}
              <Col md={12} xl={6}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting' for='labelYes'>
                    Bonus
                    {' '}
                  </Label>
                  <Input
                    className={'form-control'}
                    type='number'
                    value={bonus}
                    placeholder='Enter Bonus'
                    onChange={(e) => {
                      handleChange(e, 'bonus')
                    }}
                    min="0"
                  />
                </FormGroup>
              </Col>
            </Row>
            {isSentimentAdded &&
              <Row className='mt-2'>
                <Col md={12} xl={6}>
                  <FormGroup className='form-group '>
                    <Label className='edit-label-setting' for="place">
                      Sentiment
                      {' '}
                    </Label>
                    <Input
                      className={'form-control'}
                      disabled={adminPermission?.OT_EVENT === 'R' || !isSentimentAdded}
                      name="sentiment"
                      onChange={event => handleChange(event, 'sentiment')}
                      type="select"
                      value={sentiment}
                    >
                      <option value='YES' key='YES'>YES</option>
                      <option value='NO' key='NO'>NO</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            }
          </Form>
        </section>
      </section>
    </main>
  )
})

AddEventForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  match: PropTypes.object,
  isEdit: PropTypes.string,
  setIsEdit: PropTypes.func,
  adminPermission: PropTypes.object,
  Auth: PropTypes.string,
  setSubmitDisableButton: PropTypes.func
}

AddEventForm.displayName = AddEventForm
export default connect(null, null, null, { forwardRef: true })(AddEventForm)
