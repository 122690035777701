import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'

import PassbookList from './PassbookList'
import UsersListHeader from '../Component/UsersListHeader'
import UsersListMainHeader from '../Component/UsersListMainHeader'

import SortableColumn from './sortableColumn'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'
// import { getGameCategory } from '../../../actions/league'
import { getMatchDetails, getMatchList, getMatchesTotalCount } from '../../../actions/match'
import { getLeaguePassbookList, getLeagueTransactionsTotalCount, getPassbookList, getTransactionTypeFilter, getTransactionsTotalCount } from '../../../actions/passbook'

function Passbook (props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const content = useRef()
  const { Id } = useParams()

  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [userToPass, setUserToPass] = useState(false)
  const [systemUserToPass, setSystemUserToPass] = useState(false)
  const [tdsToPass, setTdsToPass] = useState(false)
  const [leagueToPass, setLeagueToPass] = useState(false)
  const [userToPassId, setUserToPassId] = useState('')
  const [leagueToPassId, setLeagueToPassId] = useState('')
  const [SeriesLeaderBoardUserRankLink, setSeriesLeaderBoardUserRankLink] = useState('')
  const [isSeriesLeaderBoardUserRank, setIsSeriesLeaderBoardUserRank] = useState('')
  const [tdsToPassId, setTdsToPassId] = useState(0)
  const [leagueToPassbookMatch, setLeagueToPassbookMatch] = useState('')
  const [leagueToPassbookLeague, setLeagueToPassbookLeague] = useState('')
  const [transactionStatus, setTransactionStatus] = useQueryState('transactionStatus', '')
  const [particulars, setParticulars] = useQueryState('particulars', '')
  const [eType, setEType] = useQueryState('etype', '')
  const [userType, setUserType] = useQueryState('eUserType', '')
  const [transactionTypeList, setTransactionTypeList] = useState([])
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [SportsType, setSportsType] = useQueryState('sportsType', '')
  const [match, setMatch] = useQueryState('iMatchId', '')
  const [selectedMatch, setSelectedMatch] = useState([])
  const [listOfMatches, setListOfMatches] = useState([])
  const [activePageMatch, setActivePageMatch] = useState(1)
  const [matchInput, setMatchInput] = useState('')
  const [searchValue, setSearchValue] = useState([])
  const [matchTotal, setMatchTotal] = useState(0)

  const token = useSelector(state => state?.auth?.token)
  const passbookList = useSelector(state => state?.passbook?.passbookList)
  const transactionTypes = useSelector(state => state?.passbook?.transactionTypeList)
  const GameCaegoryList = useSelector(state => state?.league?.GamecategoryList)
  const matchList = useSelector(state => state?.match?.matchList)

  // useEffect set to the navigate state
  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.searchValue) {
      setSearchText(obj?.searchValue)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
    if (obj?.searchType) {
      setSearchType(obj?.searchType)
    }
    setSystemUserToPass(location?.state?.systemUserToPassbook)
    setUserToPass(location?.state?.userToPassbook)
    setUserToPassId(location?.state?.id)
    setTdsToPass(location?.state?.tdsToPassbook)
    setTdsToPassId(location?.state?.passbookId)
    setLeagueToPass(location?.state?.leagueToPassbook)
    setLeagueToPassId(location?.state?.leaguePassbookId)
    setLeagueToPassbookMatch(location?.state?.matchNameToPassbook)
    setLeagueToPassbookLeague(location?.state?.leagueNameToPassbook)
    setSeriesLeaderBoardUserRankLink(location?.state?.SeriesLeaderBoardUserRankLink)
    setIsSeriesLeaderBoardUserRank(location?.state?.isSeriesLeaderBoardUserRank)
  }, [])

  useEffect(() => {
    if (transactionTypes) {
      setTransactionTypeList(transactionTypes)
    }
  }, [transactionTypes])
  useEffect(() => {
    dispatch(getTransactionTypeFilter(token))
  }, [particulars])

  function onHandleSearch (e) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    }
    setSearchText(e?.target?.value)
    setinitialFlag(true)
  }

  function handleOtherFilter (e) {
    setSearchType(e?.target?.value)
  }

  function getTransactionsTotalCountFunc (search, searchType, userType, dateFrom, dateTo, particulars, eType, status, SportsType, match, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const passBookData = {
      search: search?.trim(), searchType, userType, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', particulars, eType, status, SportsType, match, isFullResponse, token
    }
    // dispatch action to get TransactionsTotalCount
    dispatch(getTransactionsTotalCount(passBookData))
  }

  function leagueTransactionsTotalCountFunc (search, searchType, userType, dateFrom, dateTo, particulars, eType, status, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const id = Id
    const passBookData = {
      id, search: search?.trim(), searchType, userType, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', particulars, eType, status, isFullResponse, token
    }
    // dispatch action to get LeagueTransactionTotalCount
    dispatch(getLeagueTransactionsTotalCount(passBookData))
  }

  function getList (start, limit, sort, order, userType, search, searchType, dateFrom, dateTo, particulars, eType, status, SportsType, match, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const passBookData = {
      start, limit, sort, order, userType, search, searchType, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', particulars, eType, status, SportsType, match, isFullResponse, token
    }
    // dispatch action get PassbookList
    dispatch(getPassbookList(passBookData))
  }

  function leaguePassbookList (start, limit, sort, order, search, searchType, userType, dateFrom, dateTo, particulars, eType, status, isFullResponse) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const id = Id
    const passBookData = {
      id, start, limit, sort, order, search, searchType, userType, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', particulars, eType, status, isFullResponse, token
    }
    // dispatch action get LeaguePassbook List
    dispatch(getLeaguePassbookList(passBookData))
  }

  function getMatch (id) {
    dispatch(getMatchDetails(id, SportsType, token))
  }

  function matchListing (start, limit, search, sportsType) {
    const matchListData = { start, limit, sort: 'dCreatedAt', order: 'desc', search, sportsType: SportsType, filter: '', startDate: '', endDate: '', provider: '', season: '', format: '', tournamentFlag: false, token }
    dispatch(getMatchList(matchListData))
  }

  function getMatchesTotalCountFunc (search, sportsType) {
    const matchListData = {
      search, filter: '', startDate: '', endDate: '', sportsType: SportsType, provider: '', season: '', format: '', token
    }
    dispatch(getMatchesTotalCount(matchListData))
  }

  function onExport () {
    content?.current?.onExport()
  }

  function onRefresh () {
    content?.current?.onRefresh()
  }

  function handleUserType (e) {
    setUserType(e?.target?.value)
  }

  const onMatchPagination = useCallback(() => {
    const length = Math?.ceil(matchTotal / 10)
    if (activePageMatch < length) {
      const start = activePageMatch * 10
      const limit = 10
      matchListing(start, limit, matchInput, SportsType)
      getMatchesTotalCountFunc(searchValue, SportsType)
      setActivePageMatch(activePageMatch + 1)
    }
  })

  function onFiltering (event, type) {
    if (type === 'Particulars') {
      setParticulars(event?.target?.value)
    } else if (type === 'eType') {
      setEType(event?.target?.value)
    } else if (type === 'TransactionStatus') {
      setTransactionStatus(event?.target?.value)
    } else if (type === 'match') {
      setMatch(event ? event?.value : '')
      setSelectedMatch(event)
    } else if (type === 'sports') {
      setSportsType(event?.target?.value)
      setMatch(event ? event?.value : '')
      setSelectedMatch([])
    }
  }

  function getGameCategoryFun () {
    // dispatch(getGameCategory(token))
  }

  // Default Columns
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'Passbook ID', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'User Type', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Mobile No.', bShow: true },
      { sColumnName: 'Amount', bShow: true },
      // { sColumnName: 'Loyalty Point', bShow: true },
      { sColumnName: 'Available Total Balance', bShow: true },
      // { sColumnName: 'Promocode', bShow: true },
      { sColumnName: 'Available Bonus', bShow: true },
      // { sColumnName: 'Sport', bShow: true },
      { sColumnName: 'Type', bShow: true },
      { sColumnName: 'Transaction Type', bShow: true },
      { sColumnName: 'Transaction ID', bShow: true },
      { sColumnName: 'Request Date & Time', bShow: true },
      { sColumnName: ' Approval Date & Time', bShow: true },
      // { sColumnName: 'Match', bShow: true },
      { sColumnName: 'Remarks', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return ({
          ...c,
          bShow: !value
        })
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  function handleSubmit () {
    mutation.mutate({ sModuleName: 'TRANSACTIONS', aColumnOrder: columnNames })
  }

  const { data: columnData } = useQuery({
    queryKey: ['TRANSACTIONS'],
    queryFn: () => getDynamicColumns('TRANSACTIONS'),
    select: (data) => data?.data?.data
  })

  useEffect(() => {
    if (columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else getDefaultColumn()
  }, [columnData])

  function onHandleSport (event) {
    setSportsType(event?.target?.value)
  }

  function handleInputChange (value, type) {
    setMatchInput(value)
    setSearchType(type)
    setSearchValue(value)
  }

  return (
    <>
      <main className="main-content">
        <section className="management-section common-box">
          <UsersListMainHeader
            {...props}
            SeriesLeaderBoardUserRankLink={SeriesLeaderBoardUserRankLink}
            heading="Transactions"
            isLeagueToPassbook={leagueToPass}
            isSeriesLeaderBoardUserRank={isSeriesLeaderBoardUserRank}
            isSystemUserToPassbook={systemUserToPass}
            isTdsToPassbook={tdsToPass}
            isUserToPassbook={userToPass}
            leagueToPassbookId={leagueToPassId}
            leagueToPassbookLeague={leagueToPassbookLeague}
            leagueToPassbookMatch={leagueToPassbookMatch}
            list={passbookList}
            onExport={onExport}
            onRefresh={onRefresh}
            passbook
            refresh = 'Refresh Transaction Data'
            tdsToPassbookId={tdsToPassId}
            transactionReport
            transactionReportPage='/transaction/transaction-report'
            userToPassbookId={userToPassId}
          />
          <div className={passbookList?.rows?.length === 0 ? 'without-pagination' : ' trans-component'}>
            <UsersListHeader
              {...props}
              dateRange={dateRange}
              eType={eType}
              endDate={endDate}
              handleOtherFilter={handleOtherFilter}
              handleSearch={onHandleSearch}
              heading="Transactions"
              isLeagueToPassbook={leagueToPass}
              isSeriesLeaderBoardUserRank={isSeriesLeaderBoardUserRank}
              isSystemUserToPassbook={systemUserToPass}
              isTdsToPassbook={tdsToPass}
              isUserToPassbook={userToPass}
              leagueToPassbookId={leagueToPassId}
              leagueToPassbookLeague={leagueToPassbookLeague}
              leagueToPassbookMatch={leagueToPassbookMatch}
              list={passbookList}
              onFiltering={onFiltering}
              particulars={particulars}
              passBookID
              passbook
              search={searchText}
              searchBox
              searchType={searchType}
              setIsManageColumn={setIsManageColumn}
              setDateRange={setDateRange}
              startDate={startDate}
              tdsToPassbookId={tdsToPassId}
              transactionReport
              transactionReportPage='/transaction/transaction-report'
              transactionStatus={transactionStatus}
              userToPassbookId={userToPassId}
              handleUserType={handleUserType}
              userType={userType}
              transactionTypeList={transactionTypeList}
              GameCaegoryList={GameCaegoryList}
              handleSportType={onHandleSport}
              SportsType={SportsType}
              match={match}
              selectedMatch={selectedMatch}
              listOfMatches={listOfMatches}
              matchInput={matchInput}
              handleInputChange={handleInputChange}
              matchListing={matchListing}
              onMatchPagination={onMatchPagination}
            />
            <PassbookList
              {...props}
              ref={content}
              List={passbookList}
              endDate={endDate}
              flag={initialFlag}
              getList={getList}
              getTransactionsTotalCountFunc={getTransactionsTotalCountFunc}
              isLeaguePassbook={Id}
              isTdsToPassbook={tdsToPass}
              isUserToPassbook={userToPass}
              leaguePassbookList={leaguePassbookList}
              leagueTransactionsTotalCountFunc={leagueTransactionsTotalCountFunc}
              onExport={onExport}
              search={searchText}
              searchType={searchType}
              startDate={startDate}
              tdsToPassbookId={tdsToPassId}
              userType={userType}
              userToPassbookId={userToPassId}
              viewLink="/users/user-management/user-details"
              columnNames={columnNames}
              SportsType={SportsType}
              getGameCategory={getGameCategoryFun}
              getMatch={getMatch}
              match={match}
              setSelectedMatch={setSelectedMatch}
              listOfMatches={listOfMatches}
              setListOfMatches={setListOfMatches}
              matchInput={matchInput}
              matchList={matchList}
              matchListing={matchListing}
              getMatchesTotalCountFunc={getMatchesTotalCountFunc}
              searchValue={searchValue}
              setMatchTotal={setMatchTotal}
              eType={eType}
              particulars={particulars}
            />
          </div>
        </section>
      </main>
      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col >

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

Passbook.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object
}

export default Passbook
